*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-transform: capitalize;
}

.admission-flex-container-main{
    display: flex;
    justify-content: space-between;
}

.admissions-container-each{
    width: 60%;
}
.admissions-links-container-each{
    width: 35%;
}

.admissions-links-container-each , .admissions-container-each{
    background-color: none;
    background:none;
}
 

.admissions-links-container-each p a{
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.admissions-links-container-each p a:hover{
 color: red;
 cursor: pointer;
}
@media only screen and (max-width: 600px){
    .admission-flex-container-main {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .admissions-links-container-each, .admissions-container-each {
        background-color: none;
        background: none;
        width: 91%;
        margin: auto;
    }
    .admission-contact-us-img-container{
        width: 100%;
    }
    .admission-contact-us-img-container img{
width: 95%;
margin: auto;
    }
}

.admission-inner-heading-h2{
    color: red;
}